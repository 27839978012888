import { PageHandler } from 'components/ContentHandlers'
import { generateWebPageProps } from 'utils/next'
import { setPageCacheControlHeaders } from 'utils/cache'

export async function getServerSideProps(context) {
  setPageCacheControlHeaders(context.res)
  return generateWebPageProps(context)
}

export default function ResolveDebt({ content, isMobile }) {
  return <PageHandler pageContent={content} isMobileUA={isMobile} />
}
